import { SprintToggler } from '@app/components/core';
import { daysInSprint, tabs } from '@app/constants/app';
import { memberReportingPath } from '@app/constants/url';
import { addDays, shortDate, toFixedHours, usePrevious } from '@app/utils';
import { debounce } from '@app/utils/helpers/debounce';
import { Roles, TabsType } from '@app/utils/types';
import { MembershipEntity, MembershipStatus, OrganizationParamsType, useListSprints } from '@data';
import Button from '@mui/lab/LoadingButton';
import { useInfiniteListPmMembers, useSendMembershipReminder } from '@data/hooks/membership';
import { useOrganizationChapters } from '@data/hooks/organization';
import React, { Dispatch, ReactElement, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useParams } from 'react-router-dom';
import { Loader } from '@ui';
import EditOutlined from '@mui/icons-material/EditOutlined';
import NotificationsOutlined from '@mui/icons-material/NotificationsOutlined';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Search from '@mui/icons-material/Search';
import { addYears } from 'date-fns';

interface MembersReportingListProps {
  activeTab: TabsType;
  setActiveTab: Dispatch<TabsType>;
}

const MembersReportingList: React.FC<MembersReportingListProps> = ({ activeTab, setActiveTab }): ReactElement => {
  const theme = useTheme();
  const { organizationId } = useParams<OrganizationParamsType>();
  const [search, setSearch] = useState<string | undefined>();

  const rangeForSprints = useMemo(
    () =>
      activeTab === tabs.currentSprint
        ? { from: new Date(), to: addDays(new Date(), daysInSprint - 1) }
        : { to: new Date() },
    [activeTab],
  );

  const { data: chapters } = useOrganizationChapters(organizationId);
  const { data: sprints } = useListSprints(rangeForSprints);
  const currentSprint = sprints?.items.sort(
    (a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime(),
  )[0];

  const membersPmParams = {
    from: currentSprint?.startDate,
    to: currentSprint?.endDate,
    sprintNumber: currentSprint?.number,
    sprintId: currentSprint?.id,
    search,
    userStatus: MembershipStatus.active,
  };

  const {
    data: pmMembers,
    fetchNextPage,
    isFetching,
  } = useInfiniteListPmMembers(organizationId, membersPmParams, !!currentSprint, sprints?.items);

  const isFetchingPrev = usePrevious(isFetching);

  if (activeTab === TabsType.pastSprints && pmMembers?.pages.length === 1 && isFetchingPrev && !isFetching)
    fetchNextPage();

  const { mutate: sendMembershipReminder } = useSendMembershipReminder(organizationId, membersPmParams);

  const { t } = useTranslation();

  const columns: GridColDef[] = [
    {
      field: 'member',
      headerName: t('member'),
      flex: 1,
      minWidth: 180,
    },
    {
      field: 'hours',
      headerName: t('totalHours'),
      flex: 1,
    },
    {
      field: 'emptyDaysCount',
      headerName: t('emptyDays'),
      flex: 1,
      renderCell: (params) => (
        <Typography
          fontWeight={
            params.row.emptyDaysCount > 1 ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular
          }
          color={params.row.emptyDaysCount > 1 ? theme.palette.error[500] : theme.palette.text}
        >
          {params.row.emptyDaysCount}
        </Typography>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      sortable: false,
      flex: 1,
      minWidth: 180,
      renderCell: (params) => (
        <Box gap={1} display="flex">
          <Button
            size="small"
            component={Link}
            to={memberReportingPath(organizationId, params.row.id || '')}
            startIcon={<EditOutlined />}
          >
            {t('edit')}
          </Button>
          <Button
            size="small"
            startIcon={<NotificationsOutlined />}
            onClick={() => sendMembershipReminder({ id: params.row.id, pingSent: true })}
          >
            {params.row.pingSent ? t('pingSent') : t('ping')}
          </Button>
        </Box>
      ),
    },
  ];

  const getRowData = (memberships: MembershipEntity[]) =>
    memberships
      ? memberships
          .filter((x) => x.role !== Roles.client)
          .map((x) => ({
            ...x,
            member: `${x.user.firstName || ''} ${x.user.lastName || ''}`,
            product: x.productsCount,
            chapters: x.chapters.map((chapter) => chapters?.items?.find((x) => x.role === chapter)?.text).join(', '),
            hours: toFixedHours(x.totalMinutes / 60),
          }))
      : [];

  const debouncedSetSearch = useCallback(debounce(setSearch, 500), []);

  return (
    <>
      <Box marginBottom={3} display="flex" justifyContent="space-between" gap={2}>
        <Typography variant="h4" fontWeight={theme.typography.fontWeightBold}>
          {t('titles.membersReporting')}
        </Typography>
        <SprintToggler activeTab={activeTab} setActiveTab={setActiveTab} />
      </Box>

      <Box marginBottom={3}>
        <TextField
          label={t('search')}
          placeholder={t('search')}
          InputProps={{
            startAdornment: <Search htmlColor={theme.palette.grey[500]} />,
          }}
          onChange={(e) => debouncedSetSearch(e.target.value)}
        />
      </Box>

      {/* Fix loader, hasMore is incorrect */}
      <InfiniteScroll
        dataLength={pmMembers?.pages?.length || 0}
        next={() => fetchNextPage()}
        hasMore={
          activeTab !== TabsType.currentSprint &&
          !pmMembers?.pages.some((x) => x.sprintNumber === 1 && new Date(x.from) < addYears(new Date(), -1))
        }
        loader={<div />}
      >
        <Box>
          {pmMembers ? (
            pmMembers.pages.map((page) => {
              const rows = getRowData(page.items);
              return (
                <Box marginBottom={3} key={page.sprintNumber}>
                  <Typography variant="h6" marginTop={3} marginBottom={2}>
                    {`Sprint ${page.sprintNumber} (${page?.from ? shortDate(page.from) : ''} - ${
                      page?.to ? shortDate(page.to) : ''
                    })`}
                  </Typography>
                  <TableContainer component={Paper}>
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      autoHeight
                      paginationModel={{ page: 0, pageSize: rows.length }}
                      disableColumnFilter
                      disableColumnMenu
                      disableColumnSelector
                      disableDensitySelector
                      disableRowSelectionOnClick
                      // loading={isFetching}
                      initialState={{
                        sorting: {
                          sortModel: [{ field: 'member', sort: 'asc' }],
                        },
                      }}
                    />
                  </TableContainer>
                </Box>
              );
            })
          ) : (
            <Loader />
          )}
        </Box>
      </InfiniteScroll>
    </>
  );
};

export default MembersReportingList;
