import { MembershipEntity, PmProductsParams, UpdateProduct } from '@data/utils/types';
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';

import type {
  IError,
  PmProductsInfiniteData,
  PmParams,
  SprintEntity,
  ProductParams,
  ProductEntity,
  ListResponse,
} from '@data';
import * as api from '../api';
import * as queryKeys from '../constants/keys';

export const useProduct = (productId: string): UseQueryResult<ProductEntity, IError> => {
  const queryClient = useQueryClient();
  return useQuery([queryKeys.PRODUCT, productId], () => api.fetchProduct(productId), {
    staleTime: 1000,
    placeholderData: () =>
      queryClient
        .getQueryData<ListResponse<ProductEntity>>(queryKeys.PRODUCTS)
        ?.items.find((product) => product.id === productId),
  });
};

export const useListProducts = (
  organizationId: string,
  params?: ProductParams,
): UseQueryResult<{ items: ProductEntity[]; count: number }, IError> =>
  useQuery([queryKeys.PRODUCTS, params], () => api.fetchProducts(organizationId, params), { staleTime: 1000 });

export const useListProductClients = (
  productId?: string,
): UseQueryResult<{ items: MembershipEntity[]; count: number }, IError> =>
  useQuery([queryKeys.PRODUCT_CLIENTS, productId], () => api.fetchProductClients(productId), {
    enabled: !!productId,
  });

export const useListPmProducts = (
  organizationId: string,
  params: PmProductsParams,
): UseQueryResult<ListResponse<ProductEntity>, IError> =>
  useQuery([queryKeys.PM_PRODUCTS, { organizationId, params }], () => api.fetchPmProducts(organizationId, params));

export const useInfiniteListPmProducts = (
  organizationId: string,
  params: PmParams,
  enabled: boolean,
  sprints?: SprintEntity[],
): UseInfiniteQueryResult<PmProductsInfiniteData, IError> =>
  useInfiniteQuery(
    [queryKeys.PM_PRODUCTS, { organizationId, params }],
    ({ pageParam }) => api.fetchInfinitePmProducts(organizationId, params, { pageParam }),
    {
      keepPreviousData: true,
      enabled,
      getNextPageParam: (lastPage: {
        from: Date | string;
        to: Date | string;
        sprintNumber: number;
        sprintId: string;
      }) => {
        const lastPageFromDate = new Date(lastPage.from);
        const sortedSprints = (sprints ?? []).sort(
          (a, b) => new Date(a.endDate).getTime() - new Date(b.endDate).getTime(),
        );
        const nearestSprintIndex = sortedSprints.findIndex((sprint) => new Date(sprint.endDate) >= lastPageFromDate);
        const sprint = nearestSprintIndex > 0 ? sortedSprints[nearestSprintIndex - 1] : undefined;

        return {
          from: sprint?.startDate,
          to: sprint?.endDate,
          sprintNumber: sprint?.number,
          sprintId: sprint?.id,
        };
      },
    },
  );

export const useUpdateProduct = (
  organizationId: string,
  params: PmProductsParams,
): UseMutationResult<ProductEntity, IError, { productId: string; values: UpdateProduct }, unknown> => {
  const queryClient = useQueryClient();
  return useMutation(({ productId, values }) => api.updateProduct(productId, values), {
    onMutate: async ({ productId, values }) => {
      const previousPmProducts = queryClient.getQueryData<ListResponse<ProductEntity>>([
        queryKeys.PM_PRODUCTS,
        { organizationId, params },
      ]);

      if (previousPmProducts) {
        queryClient.setQueryData<ListResponse<ProductEntity>>([queryKeys.PM_PRODUCTS, { organizationId, params }], {
          ...previousPmProducts,
          items: previousPmProducts?.items?.map((product) => {
            if (product.id === productId) {
              return { ...product, ...values };
            }
            return product;
          }),
        });
      }

      return { previousPmProducts };
    },

    onError: (_, __, context: { previousPmProducts: ListResponse<ProductEntity> }) => {
      queryClient.setQueryData([queryKeys.PM_PRODUCTS, { organizationId, params }], context.previousPmProducts);
    },

    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.PM_PRODUCTS, { organizationId, params }]);
    },
  });
};
